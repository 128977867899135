
export default {
  summaryReportStatus() {
    return [
      {
        id: null,
        name: "All Status",
      },
      {
        id: "Re-Review",
        name: "Re-Review",
      },
      {
        id: "In Review",
        name: "In Review",
      },
      {
        id: "Declined",
        name: "Declined",
      },
      {
        id: "Finalized",
        name: "Finalized",
      },
      {
        id: "Evaluator Reviewed",
        name: "Evaluator Reviewed",
      },
      {
        id: "Evaluator QC Reviewed",
        name: "Evaluator QC Reviewed",
      },
      {
        id: "Hidden",
        name: "Hidden",
      },
      {
        id: "Pending Client Approval",
        name: "Pending Client Approval",
      },
    ];
  },
  summaryReportUpdateStatus() {
    return [
      {
        id: "Re-Review",
        name: "Re-Review",
      },
      {
        id: "In Review",
        name: "In Review",
      },
      {
        id: "Declined",
        name: "Declined",
      },
      {
        id: "Finalized",
        name: "Finalized",
      },
      {
        id: "Evaluator Reviewed",
        name: "Evaluator Reviewed",
      },
      {
        id: "Evaluator QC Reviewed",
        name: "Evaluator QC Reviewed",
      },
      {
        id: "Hidden",
        name: "Hidden",
      },
      {
        id: "Pending Client Approval",
        name: "Pending Client Approval",
      },
    ];
  },
  requestReportStatus() {
    return [
      {
        id: null,
        name: "All Status",
      }, {
        id: "Pending",
        name: "Pending",
      }, {
        id: "Scheduled",
        name: "Scheduled",
      }, {
        id: "No Reply",
        name: "No Reply",
      }, {
        id: "Declined",
        name: "Declined",
      }, {
        id: "Hidden",
        name: "Hidden",
      }, {
        id: "Complete",
        name: "Complete",
      }, {
        id: "In Progress",
        name: "In Progress",
      }, {
        id: "No Show",
        name: "No Show",
      }, {
        id: "Rescheduled",
        name: "Rescheduled",
      },
    ];
  },
  requestReportUpdateStatus() {
    return [
      {
        id: "Pending",
        name: "Pending",
      }, {
        id: "Scheduled",
        name: "Scheduled",
      }, {
        id: "No Reply",
        name: "No Reply",
      }, {
        id: "Declined",
        name: "Declined",
      }, {
        id: "Complete",
        name: "Complete",
      }, {
        id: "In Progress",
        name: "In Progress",
      }, {
        id: "No Show",
        name: "No Show",
      }, {
        id: "Rescheduled",
        name: "Rescheduled",
      },
    ];
  },
  followupsReportStatus() {
    return [
      {
        id: null,
        name: "All Status",
      },
      {
        id: "Pending",
        name: "Pending",
      },
      {
        id: "Scheduled",
        name: "Scheduled",
      },
      {
        id: "Declined",
        name: "Declined",
      },
      {
        id: "Hidden",
        name: "Hidden",
      },
      {
        id: "Complete",
        name: "Complete",
      },
      {
        id: "In Review",
        name: "In Review",
      },
      {
        id: "Client Follow-Up Needed",
        name: "Client Follow-Up Needed",
      },
    ];
  },
  followupsReportUpdateStatus() {
    return [
      {
        id: "Pending",
        name: "Pending",
      },
      {
        id: "Scheduled",
        name: "Scheduled",
      },
      {
        id: "Declined",
        name: "Declined",
      },
      {
        id: "Complete",
        name: "Complete",
      },
      {
        id: "In Review",
        name: "In Review",
      },
      {
        id: "Client Follow-Up Needed",
        name: "Client Follow-Up Needed",
      },
    ];
  },
  followupsUpdateStatusWithHidden() {
    return [
      {
        id: "Pending",
        name: "Pending",
      },
      {
        id: "Scheduled",
        name: "Scheduled",
      },
      {
        id: "Declined",
        name: "Declined",
      },
      {
        id: "Hidden",
        name: "Hidden",
      },
      {
        id: "Complete",
        name: "Complete",
      },
      {
        id: "In Review",
        name: "In Review",
      },
      {
        id: "Client Follow-Up Needed",
        name: "Client Follow-Up Needed",
      },
    ];
  }

}