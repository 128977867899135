<template>
  <div class="modal box_up fade" id="history" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="downloadLabel">
            <h2 class="heading_2 font_size-20">History</h2>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <Spinner ref="Spinner"></Spinner>
        <div class="modal-body details_eval">
          <div class="row justify-content-center">
            <div class="col-xs-10 col-md-10 main-timeline cust_scroll">
              <div v-show="isShowHistory">
                <div class="timeline" v-if="history.length">
                  <div :class=" indx % 2 == 0 ? 'container_timeline left' : 'container_timeline right' " v-for="(history, indx) in history" :key="indx" >
                    <div class="date date_set">
                      {{ history.history_date }}
                    </div>
                    <div class="content">
                      <h1>{{ history.history_key }}</h1>
                      <h2>{{ history.history_value }}</h2>
                      <h6>By: {{ history.user_name }}</h6>
                      <h6 v-if="!history.user_exist">
                        (User does not exists in our system)
                      </h6>
                    </div>
                  </div>
                </div>
                <div v-if="!history.length" class="row">
                  <div class="col-xs-12 col-md-12">
                    <h2 class="text-center no_data mb-5">
                      There is no history
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
export default {
  name: "DatasetModal",
  props: ["history", "isShowHistory"],
  components: {
    Spinner,
  },
};
</script>