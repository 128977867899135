<template>
  <div class="main_content program_emp_search align-items-center" style="min-height: 80vh">
    <div class="row justify-content-between mb_40">
      <div class="col-12 col-md-12 col-xl-7 col-xxl-5">
        <div class="heading">
          <h2 class="Employee_head heading-dark">Requests</h2>
          <h3 class="Program_dashboard_head">
            <!-- <router-link class="text-darks hov_router" :to="{name: 'client_manager'}"> PBE Management Dashboard </router-link> / -->
            <router-link class="text-darks hov_router" :to="{name: 'client_manager'}">Program Management  </router-link> / 
            <span class="breadcrumb-item active" style="color: #ff9500 !important;"> {{ clientName }}</span>
            <!-- <router-link class="text-darks hov_router" :to="{name: 'client_manager'}">  Requests </router-link> -->
          </h3>
        </div>
      </div>
    </div>
    <div class="self_report">
      <div class="row">
        <div class="col-md-12">
          <div class="white_card border_radius_20">
            <div class="activity_region p-3 border-bottom tab_report mb_18 pb_14">
              <div class="row">
                <div class="col-12 col-sm-12 col-lg-8 align-self-center text-center text-lg-start">
                  <h2 class="mb_20">Scheduled Requests</h2>
                </div>
                <div class="col-xs-12 col-sm-4 text-end" style="display: none">
                  <a href="javascript:" class="reset_btn">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                      x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000"
                      xml:space="preserve">
                      <metadata>
                        Svg Vector Icons : http://www.onlinewebfonts.com/icon
                      </metadata>
                      <g>
                        <path
                          d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z">
                        </path>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="table_card" style="box-shadow: none; padding: 20px 0 !important">
                    <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length float-none float-lg-start d-lg-inline-block text-center text-lg-start" id="datatable_length">
                        <label>Reports
                          <select name="datatable_length" aria-controls="datatable" class=""
                            v-model.number="itemsPerPage" @change="getScheduledRequests">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div class="align-items-center d-flex justify-content-center justify-content-lg-end flex-wrap mt-3 mt-lg-0">
                        <div id="datatable_filter" class="dataTables_filter mr_20 mr_mini float-md-none float-lg-end  text-center">
                          <h1 v-if="scheduledCheckbox.length" class="updatestatus update_mb text-center me-0 me-lg-3">Update Status </h1>
                          <label class="float-none" v-else><input type="search" class=""
                              aria-controls="datatable" v-model="listConfig.search_string" placeholder="Search"
                              v-on:keyup.enter="getScheduledRequests" />
                            <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getScheduledRequests" class="search_icon slty_up">
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00"
                                class="bi bi-search" viewBox="0 0 16 16">
                                <path
                                  d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </a>
                            <a href="javascript:void(0)" v-if="listConfig.search_string" @click="
                                listConfig.search_string = null;
                                getScheduledRequests();
                              " class="crossicon x_icon">
                              X
                            </a>
                          </label>
                        </div>
                        <div class="region_select status_box new_status status_select i_sts_li mb-5 mr_20 mr_mini">
                          <v-select v-if="scheduledCheckbox.length" :reduce="(update_status_list) => update_status_list.id" :options="update_status_list" label="name" placeholder="All Status" v-model="listConfig.search_status" @input="updateBulkStatus($event)" />
                          <v-select v-else :reduce="(status_list) => status_list.id" :options="status_list" label="name" placeholder="All Status" v-model="listConfig.search_status" @input="updateBulkStatus($event)" />
                        </div>
                        <div v-if="scheduledCheckbox.length && oldSelectedCheckbox != 'Hidden'" class=" region_select status_box new_status status_select mb-4 mb-lg-5">
                          <a href="javascript:" class="comman_icons " @click="updateBulkStatus('Hidden')">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16.975" viewBox="0 0 17 16.975">
                              <path id="Path_107" data-name="Path 107"
                                d="M11.431,15.437a7.874,7.874,0,0,1-8.974-5.364A7.836,7.836,0,0,1,3.685,7.693m4.6.714a2.358,2.358,0,0,1,3.335,3.335M8.289,8.406l3.334,3.334M8.291,8.407,5.705,5.821m5.92,5.92,2.586,2.586M2.884,3,5.705,5.821m0,0a7.862,7.862,0,0,1,11.752,4.252,7.879,7.879,0,0,1-3.247,4.253m0,0,2.821,2.821"
                                transform="translate(-1.457 -1.586)" fill="none" stroke="#a9a9a9" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2" />
                            </svg>
                            <span class="ml_10"><b>Hide</b></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log report_table request_table">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <th style="min-width: 10px" class="sort sml_check">
                              <div class="form_check_box tp_mr d-flex align-items-center mt-1">
                                <input v-if="data_length == 0" type="checkbox" aria-label="..." class="form-check-input ms-0" disabled />
                                <input v-else type="checkbox" v-model="selectAll" @change="checkSelected($event)" aria-label="..." id="flexCheckDefault" class="form-check-input ms-0" />
                                <label for="flexCheckDefault" class=""> </label>
                              </div>
                            </th>
                            <th scope="col" class="sort req_to_req_on">
                              <SortIcons :lable="'REQUEST ON'" :listConfig="listConfig" :sortBy="'alchemer_schedule_requests.created_at'" @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort req_to_name">
                              <SortIcons :lable="'First Name'" :listConfig="listConfig" :sortBy="'users.first_name'" @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort req_to_name">
                              <SortIcons :lable="'Last Name'" :listConfig="listConfig" :sortBy="'users.last_name'" @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort req_to_email">
                              <SortIcons :lable="'Email Address'" :listConfig="listConfig" :sortBy="'alchemer_schedule_requests.user_email'" @sorting="sorting($event)" />
                            </th>
                            <th scope="col" class="sort sml_status req_status move_icon" >
                              <SortIcons :lable="'STATUS'" :listConfig="listConfig" :sortBy="'status'"
                                @sorting="sorting($event)" />
                              <svg style="margin-left: 10px" v-tooltip="
                                  'Pending, Scheduled, No Reply, Declined, Hidden, Complete, In Progress, No Show & Rescheduled'
                                " xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                                viewBox="0 0 14 18.346">
                                <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                                <path data-name="Path 9427"
                                  d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                                  transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                              </svg>
                            </th>
                            <th align="center" scope="col" style="text-align: center;" class="req_to_action">ACTION</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(schReq, indx) in scheduled_requests" :key="indx">
                            <td style="min-width: 10px">
                              <div class="form_check_box d-flex align-items-center">
                                <input type="checkbox" @change="checkSelected($event)" v-model="scheduledCheckbox" :value="schReq.id" aria-label="..." :id="'flexCheckDefault' + indx" class="form-check-input ms-0" />
                                <label :for="'flexCheckDefault' + indx" class=""></label>
                              </div>
                            </td>
                            <td><p>{{ schReq.request_on }}</p></td>
                            <td><p>{{ schReq.Fname }}</p></td>
                            <td><p>{{ schReq.Lname }}</p></td>
                            <td><p class="email_tag"><a href="mailto:">{{ schReq.email }}</a></p></td>
                            <td><p>{{ schReq.status }}</p></td>
                            <td style="text-align:center;">
                              <div class="edit_btn">
                                <p>
                                  <a href="javascript:" @click="completeStatus(schReq.id)" aria-current="page" title="Complete" class="view-dshboad-btn router-link-exact-active router-link-active ms-2" v-if="listConfig.search_status != 'Complete' &&
                                  oldSelectedCheckbox != 'Complete'">
                                    <img class="view-icon" :src="require('@/assets/images/dashboard/complete.svg')" alt="img" />
                                  </a>
                                  <a href="javascript:" @click="showReportDataSet(schReq.dataset,schReq.Fname+' '+schReq.Lname)" aria-current="page" title="Data Set" class=" router-link-exact-active router-link-active" data-bs-toggle="modal" data-bs-target="#dataset">
                                    <img class="view-icon" :src="require('@/assets/images/dashboard/Database.png')"
                                      alt="img" />
                                  </a>
                                  <a href="javascript:" @click="getSingleRequestHistory(schReq.id)" aria-current="page" data-bs-toggle="modal" data-bs-target="#history" title="History" class="router-link-exact-active router-link-active
                                      view-dshboad-btn">
                                    <img :src="require('@/assets/images/dashboard/timer.png')" alt="img" class="edit-icon"/>
                                  </a>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="data_length == 0">
                            <td colspan="7" class="text-center">
                              No Records found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">
                      {{ positionText }}
                    </p>
                    <VPagination v-if="data_length" class="dataTables_paginate" :totalPages="pages.length"
                      :perPage="itemsPerPage" :currentPage.sync="currentPage" @pagechanged="onPageChange" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DatasetModal v-bind:downloadMessage="downloadMessage" v-bind:dashboard="dashboard"  v-bind:dataSet="dataSet" v-bind:isEmptyDataSet="isEmptyDataSet" ref="datasetModal"></DatasetModal>
    <HistoryModal v-bind:history="requestsHistory" v-bind:isShowHistory="showHistory" ref="historyModal"></HistoryModal> 
    <!-- <div class="modal box_up fade" id="dataset" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header download-btn-right">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">Data Set</h2>
            </h5>
            <div class="down_cross">
              <a href="javascript:" class="ml_12 xl_download" v-if="this.dataSet" @click="exportDataset()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
              <g data-name="Group 12689">
                <path
                  data-name="Path 114"
                  d="M4 14.5v.875A2.625 2.625 0 0 0 6.625 18h8.75A2.625 2.625 0 0 0 18 15.375V14.5M14.5 11 11 14.5m0 0L7.5 11m3.5 3.5V4"
                  transform="translate(-3 -3)"
                  style="
                    fill: none;
                    stroke: #a9a9a9;
                    stroke-linecap: round;
                    stroke-linejoin: round;
                    stroke-width: 2px;
                  "
                />
              </g>
            </svg>
            Download
              </a>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="resetDataSet" ></button>
            </div>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-12 col-md-12 main-timeline cust_scroll">
                <div class="table table-responsive">
                  <table class="table historyTable" width="100%" align="center">
                    <tr>
                      <th style="width: 10%">ID</th>
                      <th style="width: 25%">QUESTION</th>
                      <th style="width: 65%">ANSWER</th>
                    </tr>
                    <tr v-for="(dataValue, dataKey) in dataSet" :key="dataKey">
                      <td v-html="dataKey"></td>
                      <td v-html="dataValue.question"></td>
                      <td v-html="dataValue.answer ? dataValue.answer : 'Not Answered'"></td>
                    </tr>
                    <tr v-if="!this.dataSet">
                      <td colspan="6" class="text-center">No Records found</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="modal box_up fade" id="history" tabindex="-1" aria-labelledby="downloadLabel" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadLabel">
              <h2 class="heading_2 font_size-20">History</h2>
            </h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
          </div>
          <Spinner ref="Spinner"></Spinner>
          <div class="modal-body details_eval">
            <div class="row justify-content-center">
              <div class="col-xs-10 col-md-10 main-timeline cust_scroll">
                <div v-show="showHistory">
                  <div class="timeline" v-if="requestsHistory.length">
                    <div
                      :class="
                        indx % 2 == 0
                          ? 'container_timeline left'
                          : 'container_timeline right'
                      "
                      v-for="(history, indx) in requestsHistory"
                      :key="indx"
                    >
                      <div class="date date_set">
                        {{ history.history_date }}
                      </div>
                      <div class="content">
                        <h1>{{ history.history_key }}</h1>
                        <h2>{{ history.history_value }}</h2>
                        <h6>By: {{ history.user_name }}</h6>
                      </div>
                    </div>
                  </div>
                  <div v-if="!requestsHistory.length" class="row">
                    <div class="col-xs-12 col-md-12">
                      <h2 class="text-center no_data mb-5">
                        There is no history
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import $ from "jquery";
import commonFunction from "../../mixin/commonFunction";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";
import ConfirmDialogue from "@/components/ConfirmDialogue";
// import Spinner from "../../components/Spinner.vue";
import DatasetModal from "@/components/DatasetModal";
import HistoryModal from "@/components/HistoryModal";
import commonService from "../../services/commonService";

export default {
  name: "employee_search",
  mixins: [commonFunction],
  components: {
    VPagination,
    SortIcons,
    ConfirmDialogue,
    // Spinner,
    DatasetModal,
    HistoryModal
  },
  data() {
    return {
      downloadMessage: 'Dataset',
      dashboard: 'Program Management Requests',
      show: false,
      users: [],
      users_data: [],
      selected: true,
      isLoader: false,
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "alchemer_schedule_requests.created_at",
        order: "DESC",
        search_string: null,
        search_status: null,
        start_date: null,
        end_date: null,
        regionBy: null,
        clientId: '',
      },
      data_length: 0,
      isDisabled: "",
      addPermission: false,
      deletePermission: false,
      delete_id: 0,
      delete_res: "",
      delete_reason: "",
      status_list: commonService.requestReportStatus(),
      update_status_list: commonService.requestReportUpdateStatus(),
      // status_list: [
      //   {
      //     id: null,
      //     name: "All Status",
      //   }, {
      //     id: "Pending",
      //     name: "Pending",
      //   }, {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   }, {
      //     id: "No Reply",
      //     name: "No Reply",
      //   }, {
      //     id: "Declined",
      //     name: "Declined",
      //   }, {
      //     id: "Hidden",
      //     name: "Hidden",
      //   }, {
      //     id: "Complete",
      //     name: "Complete",
      //   },
      // ],
      // update_status_list: [
      //   {
      //     id: "Pending",
      //     name: "Pending",
      //   }, {
      //     id: "Scheduled",
      //     name: "Scheduled",
      //   }, {
      //     id: "No Reply",
      //     name: "No Reply",
      //   }, {
      //     id: "Declined",
      //     name: "Declined",
      //   }, {
      //     id: "Complete",
      //     name: "Complete",
      //   },
      // ],
      clearButton: true,
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      base_url: null,

      scheduled_requests: [],
      scheduledCheckbox: [],
      scheduledCheckboxArr: {
        clientId: '',
        status: null,
        checkboxes: [],
      },
      oldSelectedCheckbox: null,
      showHistory: false,
      dataSet: [],
      isEmptyDataSet: true,
      requestsHistory: [],
      clientName: 'Requests',
    };
  },
  computed: {
    selectAll: {
      get: function () {
        return this.scheduled_requests
          ? this.scheduledCheckbox.length == this.scheduled_requests.length
          : false;
      },
      set: function (value) {
        var scheduledCheckbox = [];
        if (value) {
          this.scheduled_requests.forEach(function (schReq) {
            scheduledCheckbox.push(schReq.id);
          });
        }
        this.scheduledCheckbox = scheduledCheckbox;
        this.listConfig.search_status = "";
      },
    },
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getScheduledRequestsData();
    },
  },
  destroyed() {
    this.$parent.clientManagerDashboard = false;
  },
  mounted() {
    document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
    this.base_url = process.env.VUE_APP_API_URL;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "alchemer_schedule_requests.created_at",
      order: "DESC",
      search_string: null,
      search_status: null,
      start_date: null,
      end_date: null,
      regionBy: null,
      clientId: '',
    };
    this.getScheduledRequestsData();
    if (this.$route.params.successMsg) {
      this.$toast.success(this.$route.params.successMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    if (this.$route.params.errorMsg) {
      this.$toast.error(this.$route.params.errorMsg, {
        position: "top-right",
        duration: 5000,
      });
    }
    this.$parent.clientManagerDashboard = true;
  },
  methods: {
    async completeStatus(requestId) {
      const confirmComplete = await this.$refs.confirmDialogue.show({
        title: "Complete Request",
        message: "Are you sure you want to complete request?",
        okButton: "Complete",
      });
      if (confirmComplete) {
        if (localStorage.getItem("userToken")) {
          var requestData = { 
            requestId: requestId,
            status: "Complete"
          };
          var _this = this;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/client-requests-complete-status",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequest(requestData)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                this.listConfig.search_status = "";
                this.scheduledCheckbox = [];
                this.getScheduledRequests();
              } else {
                _this.$toast.error(data.message, {
                  position: "top-right",
                });
              }
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            })
            .catch(({ response }) => {
              console.log(response);
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    checkSelected(event) {
      if (event.target.checked) {
        this.listConfig.search_status = "";
      } else {
        if (this.scheduledCheckbox.length == 0) {
          this.listConfig.search_status = this.oldSelectedCheckbox;
        }
      }
    },    
    showReportDataSet(dataSet,username){
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = true;
        if(dataSet != null) {
          var i = 1;
          for (const item in dataSet) {
            dataSet[item].id = i++;
            if(dataSet[item].options){
              for (const item1 in dataSet[item].options) {
                if(dataSet[item].options[item1].answer){
                  if(dataSet[item].answer)
                    dataSet[item].answer += ', '+dataSet[item].options[item1].answer;
                  else
                    dataSet[item].answer = dataSet[item].options[item1].answer;
                }
              }
            }
            if(dataSet[item].subquestions){
              for (const item2 in dataSet[item].subquestions) {
                if(dataSet[item].subquestions[item2].answer){
                  if(dataSet[item].answer){
                    dataSet[item].answer += ', '+dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                  }
                  else{
                    dataSet[item].answer = dataSet[item].subquestions[item2].question+' : '+dataSet[item].subquestions[item2].answer;
                  }
                }
              }
            }
          }
          this.dataSet = dataSet;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
          this.isEmptyDataSet = Object.keys(dataSet).length === 0;
          this.downloadMessage = "Dataset of scheduled for "+username;
        } else {
          this.dataSet = [];
          this.isEmptyDataSet= true;
          _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        }
      } else {
        _this.$refs.datasetModal.$refs.Spinner.modalLoader = false;
        $('.modal-backdrop').remove();
        $(document.body).removeClass("modal-open");
        this.tokenExpired();
      }
    },
    getSingleRequestHistory(requestId) {
      if (requestId) {
        this.showHistory = false;
        var requestData = { requestId: requestId };
        if (localStorage.getItem("userToken")) {
          var _this = this;
          _this.$refs.historyModal.$refs.Spinner.modalLoader = true;
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/requests-all-history",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {request_data : this.encodeAPIRequest(requestData)},
          };
          _this
            .axios(config)
            .then(({ data }) => {
              if (data.success) {
                var decodedJson = _this.decodeAPIResponse(data.data);
                _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
                this.requestsHistory = decodedJson.records;
                this.showHistory = true;
              }
            })
            .catch(({ response }) => {
              _this.$refs.historyModal.$refs.Spinner.modalLoader = false;
              console.log(response);
            });
        } else {
          this.tokenExpired();
        }
      }
    },
    async updateBulkStatus($event) {
      if (this.scheduledCheckbox.length && $event != null) {
        const confirmUpdate = await this.$refs.confirmDialogue.show({
          title: "Update Status",
          message: "Are you sure you want to update status?",
          okButton: "Update",
        });
        if (confirmUpdate) {
          if(this.$route.params.id != '' && this.$route.params.id != undefined) {
            this.scheduledCheckboxArr.clientId = this.$route.params.id;
          }
          this.scheduledCheckboxArr.status = $event;
          this.scheduledCheckboxArr.checkboxes = this.scheduledCheckbox;
          if (localStorage.getItem("userToken")) {
            var _this = this;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            let config = {
              method: "post",
              url: process.env.VUE_APP_API_URL + "/api/client-manager-requests-status-update",
              headers: {
                Authorization: `Bearer ${window.localStorage.getItem(
                  "userToken"
                )}`,
              },
              data: {request_data : this.encodeAPIRequest(this.scheduledCheckboxArr)},
            };
            _this
              .axios(config)
              .then(({ data }) => {
                if (data.success) {
                  _this.$toast.success(data.message, {
                    position: "top-right",
                  });
                  this.listConfig.search_status = "";
                  this.scheduledCheckbox = [];
                  this.getScheduledRequests();
                } else {
                  _this.$toast.error(data.message, {
                    position: "top-right",
                  });
                }
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              })
              .catch(() => {
                //
              });
          } else {
            this.tokenExpired();
          }
        }
      } else {
        setTimeout(() => {
          this.scheduledCheckbox.length = 0;
          this.getScheduledRequests()
        }, 100);
      }
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getScheduledRequestsData();
    },
    getScheduledRequests() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getScheduledRequestsData();
    },
    getScheduledRequestsData() {
      if (localStorage.getItem("userToken")) {
        var _this = this;
        _this.oldSelectedCheckbox = this.listConfig.search_status;
        if(_this.$route.params.id != '' && _this.$route.params.id != undefined) {
          _this.listConfig.clientId = _this.$route.params.id;
        }
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "post",
          url: process.env.VUE_APP_API_URL + "/api/client-manager-scheduled-requests",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          data: {request_data : this.encodeAPIRequestWithImages(_this.listConfig)},
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedData = _this.decodeAPIResponse(data.data);
            _this.clientName = decodedData.clientName;
            _this.scheduled_requests = decodedData.scheduled_requests;
            _this.data_length = decodedData.scheduled_requests_count;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({response}) => {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
             if (response.status == 403) {
               _this.$router.push({ name: "403" });
             }
             if (response.status == 404) {
               _this.$router.push({ name: "404" });
             
            }
          });
      } else {
        this.tokenExpired();
      }
    },
  },
};
</script>